import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { ProductForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class ProductUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Ажил',
            key: shortid.generate(),
            name: '',
            images: [],
            description: '',
            size: '',
            color: '',
            category_id: '',
            categories: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchCategories()
    }

    fetchCategories = async () => {
        try {
            const { data } = await axios.get('/categories', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                categories: data
            })
        } catch (e) {
            //
        }
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/products/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.title,
                description: data.description,
                images: data.images,
                size: data.size,
                color: data.color,
                category_id: data.category_id,
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            description,
            images,
            size,
            color,
            category_id,
            categories
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <ProductForm
                    key={key}
                    path={`products/${id}`}
                    method="put"
                    title={name}
                    description={description}
                    images={images}
                    size={size}
                    color={color}
                    category_id={category_id}
                    categories={categories}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(ProductUpdate)

import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { ProductForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class ProductNew extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            title: 'Бүтээхдэхүүн',
            key: shortid.generate(),
            categories: []
        }
    }

    componentDidMount = () => {
        this.fetchCategories()
    }

    fetchCategories = async () => {
        try {
            const { data } = await axios.get('/categories', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                categories: data
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            key,
            title,
            categories
        } = this.state
        return (
            <Dashboard title={title}>
                <ProductForm 
                    key={key}
                    categories={categories}
                    path="products"
                    method="post"
                    message="Амжилттай нэмэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(ProductNew)

import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { WorkForm } from './../forms'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class WorkUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Ажил',
            key: shortid.generate(),
            name: '',
            images: [],
            description: '',
            customer: '',
            finished: null,
            category_id: '',
            categories: []
        }
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchCategories()
    }

    fetchCategories = async () => {
        try {
            const { data } = await axios.get('/categories', {
                progress: true,
                params: {
                    rowsPerPage: -1,
                    sortBy: 'name',
                    sortOrder: 'asc'
                }
            })

            this.setState({
                key: shortid.generate(),
                categories: data
            })
        } catch (e) {
            //
        }
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/works/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.title,
                images: data.images,
                description: data.description,
                customer: data.customer,
                finished: data.finished,
                category_id: data.category_id,
                categories: data.categories
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            images,
            description,
            customer,
            finished,
            category_id,
            categories
        } = this.state

        const { match } = this.props
        const { id } = match.params

        return (
            <Dashboard title={title}>
                <WorkForm
                    key={key}
                    path={`works/${id}`}
                    method="put"
                    title={name}
                    images={images}
                    description={description}
                    customer={customer}
                    finished={finished}
                    category_id={category_id}
                    categories={categories}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(WorkUpdate)

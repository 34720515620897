import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    OutlinedInput,
} from '@material-ui/core'
import {
    DropZoneMultiple
} from './../components'
import { CustomSnackBar } from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginLeft: {
        margin: '0 10px 0 0'
    },
    marginBottom: {
        margin: '0 0 20px 0'
    }
})

class ProductForm extends Component {

    static defaultProps = {
        title: '',
        images: [],
        description: '',
        size: '',
        color: '',
        category_id: '',
        categories: []
    }

    constructor(props) {
        super(props)

        const {
            title,
            description,
            images,
            size,
            color,
            category_id,
            categories
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            title,
            description,
            images,
            size,
            color,
            category_id,
            categories
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            title: '',
            images: [],
            description: '',
            size: '',
            color: '',
            category_id: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            title,
            description,
            images,
            size,
            color,
            category_id
        } = this.state

        try {
            await axios[method](path, {
                title,
                description,
                images,
                size,
                color,
                category_id
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onChange = (path, bool) => {
        let copyImages = []
        if (bool === undefined) {
            this.setState(prevState => ({
                images: [...prevState.images, path]
            }))
        } else {
            copyImages = this.state.images.filter(val =>
                val !== path
            )
            this.setState({
                images: copyImages
            })
        }
    }

    selectChanged = e => {
        const { value } = e.target

        this.setState({ category_id: value })
    }

    render() {
        const {
            title,
            description,
            images,
            size,
            color,
            category_id,
            categories,
            isSnackOpen,
            errors
        } = this.state

        const {
            message
        } = this.props

        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.title}
                                helperText={errors.title}
                                label="Нэр"
                                value={title}
                                onChange={this.handleChange('title')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.description}
                                helperText={errors.description}
                                label="Тайлбар"
                                value={description}
                                onChange={this.handleChange('description')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={4}
                                fullWidth
                            />
                            <TextField
                                error={!!errors.size}
                                helperText={errors.size}
                                label="Хэмжээ"
                                value={size}
                                onChange={this.handleChange('size')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.color}
                                helperText={errors.color}
                                label="Өнгө"
                                value={color}
                                onChange={this.handleChange('color')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Box my={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel htmlFor="category_id-simple">Төрөл</InputLabel>
                                    <Select
                                        error={!!errors.category_id}
                                        helperText={errors.category_id}
                                        value={category_id}
                                        onChange={this.selectChanged}
                                        input={
                                            <OutlinedInput title="category_id" id="category_id-simple" />
                                        }
                                    >
                                        {
                                            categories.map(val =>
                                                <MenuItem
                                                    key={val.id}
                                                    value={val.id}
                                                >
                                                    {val.name}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box my={3}>
                                <Typography variant="subtitle1">Зураг</Typography>
                                <DropZoneMultiple
                                    uploadPath="imageupload"
                                    onChange={this.onChange}
                                    files={images}
                                    error={!!errors.images}
                                />
                            </Box>
                            <Box my={3}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                >
                                    Хадгалах
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(ProductForm)

import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid
} from '@material-ui/core'
import DropZone from './../components/DropZone'
import {
    CustomSnackBar
} from './../components'
import axios from './../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class CoPartnerForm extends Component {

    static defaultProps = {
        name: '',
        url: '',
        logo: ''
    }

    constructor(props) {
        super(props)

        const {
            name,
            url,
            logo
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            name,
            url,
            logo
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            url: '',
            logo: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            url,
            logo
        } = this.state

        try {
            await axios[method](path, {
                name,
                url,
                logo
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onChange = (path = '') => {
        this.setState({
            logo: path
        })
    }

    render() {
        const {
            name,
            url,
            logo,
            isSnackOpen,
            errors
        } = this.state

        const { marginBottom } = this.props.classes

        const { message } = this.props
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <form
                            onSubmit={this.storeOrUpdate}
                        >
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Нэр"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.url}
                                helperText={errors.url}
                                label="Линк"
                                value={url}
                                onChange={this.handleChange('url')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div className={marginBottom}>
                                <DropZone
                                    uploadPath="fileupload"
                                    onChange={this.onChange}
                                    file={logo}
                                    error={!!errors.logo}
                                />
                            </div>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Хадгалах
                            </Button>
                            <CustomSnackBar
                                message={message}
                                open={isSnackOpen}
                                snackClose={this.snackClose}
                            />
                        </form>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(CoPartnerForm)

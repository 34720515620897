import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import shortid from 'shortid'
import {
    TextField,
    Grid
} from '@material-ui/core'
import { Dashboard } from './../layouts'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class RequestForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Хүсэлт',
            key: shortid.generate(),
            fetching: false,
            disabled: false,
            name: '',
            email: '',
            message: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/requests/${id}`, { progress: true })

            this.setState({
                key: shortid.generate(),
                name: data.name,
                email: data.email,
                message: data.message
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            key,
            title,
            name,
            email,
            message
        } = this.state

        return (
            <Dashboard key={key} title={title}>
                <div>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Нэр"
                                    value={name}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    label="И-мэйл"
                                    value={email}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                />
                                <TextField
                                    label="Хүсэлт"
                                    value={message}
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    rows={10}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Dashboard>
        )
    }
}

export default withStyles(styles)(RequestForm)

import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { CategoryForm } from '../forms'
import { Dashboard } from '../layouts'

const styles = theme => ({
    //
})

class CategoryNew extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            title: 'Төрөл'
        }
    }

    render() {
        const { title } = this.state
        return (
            <Dashboard title={title}>
                <CategoryForm 
                    path="categories"
                    method="post"
                    message="Амжилттай нэмэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(CategoryNew)

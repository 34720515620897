import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import {
    CoPartnerNew,
    CoPartners,
    CoPartnerUpdate,
    EmployeeNew,
    Employees,
    EmployeeUpdate,
    ForgotPassword,
    Home,
    Login,
    NotFound,
    PasswordReset,
    PostNew,
    Posts,
    PostUpdate,
    Register,
    Requests,
    CoverNew,
    Covers,
    CoverUpdate,
    CategoryNew,
    Categories,
    CategoryUpdate,
    UserNew,
    Users,
    UserUpdate,
    WorkNew,
    Works,
    WorkUpdate,
    Products,
    ProductNew,
    ProductUpdate,
    JobUpdate,
    JobNew,
    Jobs,
    Resumes
} from './pages'
import { 
    SettingForm, 
    RequestForm,
    ResumeForm
} from './forms';

export default () => (
    <Router>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/signup"
                component={Register}
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
            />
            <Route
                path="/password/reset/:token"
                component={PasswordReset}
            />
            <Route
                path="/"
                component={Home}
                exact
            />
            <Route
                path="/users/new"
                component={UserNew}
            />
            <Route
                path="/users/:id"
                component={UserUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/works/new"
                component={WorkNew}
            />
            <Route
                path="/works/:id"
                component={WorkUpdate}
            />
            <Route
                path="/works"
                component={Works}
            />
            <Route
                path="/posts/new"
                component={PostNew}
            />
            <Route
                path="/posts/:id"
                component={PostUpdate}
            />
            <Route
                path="/posts"
                component={Posts}
            />
            <Route
                path="/employees/new"
                component={EmployeeNew}
            />
            <Route
                path="/employees/:id"
                component={EmployeeUpdate}
            />
            <Route
                path="/employees"
                component={Employees}
            />
            <Route
                path="/covers/new"
                component={CoverNew}
            />
            <Route
                path="/covers/:id"
                component={CoverUpdate}
            />
            <Route
                path="/covers"
                component={Covers}
            />
            <Route
                path="/partners/new"
                component={CoPartnerNew}
            />
            <Route
                path="/partners/:id"
                component={CoPartnerUpdate}
            />
            <Route
                path="/partners"
                component={CoPartners}
            />
            <Route
                path="/categories/new"
                component={CategoryNew}
            />
            <Route
                path="/categories/:id"
                component={CategoryUpdate}
            />
            <Route
                path="/categories"
                component={Categories}
            />
            <Route
                path="/products/new"
                component={ProductNew}
            />
            <Route
                path="/products/:id"
                component={ProductUpdate}
            />
            <Route
                path="/products"
                component={Products}
            />
            <Route 
                path="/jobs/new"
                component={JobNew}
            />
            <Route 
                path="/jobs/:id"
                component={JobUpdate}
            />
            <Route 
                path="/jobs"
                component={Jobs}
            />
            <Route
                path="/settings"
                component={SettingForm}
            />
            <Route
                path="/requests/:id"
                component={RequestForm}
            />
            <Route
                path="/requests"
                component={Requests}
            />
            <Route 
                path="/resumes/:id"
                component={ResumeForm}
            />
            <Route 
                path="/resumes"
                component={Resumes}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)

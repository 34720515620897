import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box
} from '@material-ui/core'
import { DropZone } from '../components'
import {
    CustomSnackBar
} from '../components'
import axios from '../plugins/axios'

const styles = theme => ({
    // 
})
class CoverForm extends Component {

    static defaultProps = {
        title: '',
        cover: ''
    }

    constructor(props) {
        super(props)

        const {
            title,
            cover
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            title,
            cover
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            title: '',
            cover: ''
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            title,
            cover
        } = this.state

        try {
            await axios[method](path, {
                title,
                cover
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onCoverChange = (path = '') => {
        this.setState({
            cover: path
        })
    }

    render() {
        const {
            title,
            cover,
            isSnackOpen,
            errors
        } = this.state

        const { message } = this.props
        return (
            <div>
                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.title}
                                helperText={errors.title}
                                label="Гарчиг"
                                value={title}
                                onChange={this.handleChange('title')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Box my={3}>
                                <Typography variant="subtitle1">Арын зураг/бичлэг</Typography>
                                <DropZone
                                    uploadPath="fileupload"
                                    onChange={this.onCoverChange}
                                    file={cover}
                                    error={!!errors.cover}
                                />
                            </Box>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                Хадгалах
                            </Button>
                        </Grid>
                        <CustomSnackBar
                            message={message}
                            open={isSnackOpen}
                            snackClose={this.snackClose}
                        />
                    </Grid>
                </form>
            </div >
        )
    }
}

export default withStyles(styles)(CoverForm)

import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Dashboard } from './../layouts'
import {
    TextField,
    Grid,
    Typography,
    Button,
    Box
} from '@material-ui/core'
import { 
    CustomSnackBar,
    DropZone
} from './../components'
import shortid from 'shortid'
import axios from './../plugins/axios'

const styles = theme => ({
    //
})

class SettingForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Тохиргоо',
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            about: '',
            capacity: '',
            goal: '',
            vision: '',
            value: '',
            showroomEmail: '',
            showroomNumber: '',
            showroomAddress: '',
            factoryEmail: '',
            factoryNumber: '',
            factoryAddress: '',
            facebook: '',
            instagram: '',
            twitter: '',
            cover: '',
            resumeCover: ''
        }
    }

    componentWillMount = () => {
        this.fetchData()
    }


    fetchData = async () => {
        try {
            this.setState({
                key: shortid.generate()
            })

            const { data } = await axios.get(`/option`, { progress: true })

            data.map(item =>
                this.setState({ [item.name]: item.value })
            )
        } catch (e) {
            //
        }
    }

    update = async e => {
        e.preventDefault()
        this.setState({
            disabled: true
        })

        const {
            about,
            goal,
            capacity,
            vision,
            value,
            showroomEmail,
            showroomNumber,
            showroomAddress,
            factoryEmail,
            factoryNumber,
            factoryAddress,
            facebook,
            instagram,
            twitter,
            cover,
            resumeCover
        } = this.state

        try {
            await axios.put('/option', {
                about,
                goal,
                capacity,
                vision,
                value,
                showroomEmail,
                showroomNumber,
                showroomAddress,
                factoryEmail,
                factoryNumber,
                factoryAddress,
                facebook,
                instagram,
                twitter,
                cover,
                resumeCover
            })

            this.setState({
                isSnackOpen: true,
                disabled: false
            })
        } catch (err) {
            //
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onCoverChange = (path = '') => {
        this.setState({
            cover: path
        })
    }

    onResumeCoverChange = (path = '') => {
        this.setState({
            resumeCover: path
        })
    }

    render() {
        const {
            title,
            key,
            isSnackOpen,
            about,
            goal,
            capacity,
            vision,
            value,
            showroomEmail,
            showroomNumber,
            showroomAddress,
            factoryEmail,
            factoryNumber,
            factoryAddress,
            facebook,
            instagram,
            twitter,
            cover,
            resumeCover
        } = this.state
        return (
            <Dashboard title={title}>
                <form
                    key={key}
                    onSubmit={this.update}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                Бидний тухай
                            </Typography>
                            <TextField
                                label="Бидний тухай"
                                value={about}
                                onChange={this.handleChange('about')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Box my={3}>
                                <DropZone
                                    uploadPath="imageupload"
                                    onChange={this.onCoverChange}
                                    file={cover}
                                />
                            </Box>
                            <TextField
                                label="Хүчин чадал"
                                value={capacity}
                                onChange={this.handleChange('capacity')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <TextField
                                label="Эрхэм зорилго"
                                value={goal}
                                onChange={this.handleChange('goal')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <TextField
                                label="Алсын хараа"
                                value={vision}
                                onChange={this.handleChange('vision')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <TextField
                                label="Үнэт зүйлс"
                                value={value}
                                onChange={this.handleChange('value')}
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={5}
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Ажлын байр
                            </Typography>
                            <Box my={3}>
                                <DropZone
                                    uploadPath="imageupload"
                                    onChange={this.onResumeCoverChange}
                                    file={resumeCover}
                                />
                            </Box>
                            <Typography variant="subtitle1">
                                Холбоо барих
                            </Typography>
                            <Typography variant="subtitle2">
                                Showroom
                            </Typography>
                            <TextField
                                label="И-мэйл"
                                value={showroomEmail}
                                onChange={this.handleChange('showroomEmail')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Утасны дугаар"
                                value={showroomNumber}
                                onChange={this.handleChange('showroomNumber')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Хаяг"
                                value={showroomAddress}
                                onChange={this.handleChange('showroomAddress')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Typography variant="subtitle2">
                                Үйлдвэр
                            </Typography>
                            <TextField
                                label="И-мэйл"
                                value={factoryEmail}
                                onChange={this.handleChange('factoryEmail')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Утасны дугаар"
                                value={factoryNumber}
                                onChange={this.handleChange('factoryNumber')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Хаяг"
                                value={factoryAddress}
                                onChange={this.handleChange('factoryAddress')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Typography variant="subtitle1">
                                Цахим хаягууд
                            </Typography>
                            <TextField
                                label="Facebook"
                                value={facebook}
                                onChange={this.handleChange('facebook')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Instagram"
                                value={instagram}
                                onChange={this.handleChange('instagram')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                label="Twitter"
                                value={twitter}
                                onChange={this.handleChange('twitter')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                            >
                                шинэчлэх
                            </Button>
                        </Grid>
                    </Grid>
                    <CustomSnackBar
                        message="Амжилттай шинэчлэгдлээ"
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </Dashboard>
        )
    }
}

export default withStyles(styles)(SettingForm)
